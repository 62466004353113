<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10">
        <div class="text-center mt-3">
          <img
            src="../assets/img/QA_pc.jpg"
            class="d-none d-md-inline img-fluid"
            alt=""
          />
          <img
            src="../assets/img/QA_m.jpg"
            class="d-md-none img-fluid"
            alt=""
          />
        </div>
        <h4 class="pt-3 pb-3 pb-lg-4" style="line-height: 1.7;">第一次使用Papawash嗎?<br>有任何想瞭解的，讓我們為您好好說明。</h4>
        <h4 class="text-primary">關於產品</h4>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">Papawash木瓜酵素潔顏粉跟日本的Papawash是相同的嗎？</h5>
          <h5 class="mt-3">沒錯！是完全日本原裝進口</h5>
          <p class="mt-2">
            Papawash已正式授權原裝在台灣販售，只要您在官網或透過Papawash指定通路下單購買，就是日本ESS化妝品集團出品的日本原裝木瓜酵素潔顏粉。
          </p>
        </div>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">Papawash木瓜酵素潔顏粉可以天天使用嗎？</h5>
          <h5 class="mt-3">可以！請務必天天使用喔！</h5>
          <p class="mt-2">
            Papawash木瓜酵素潔顏粉特有的木瓜酵素潔顏成分，是可以天天使用的，不只能溫和的洗去老廢角質，深入毛孔去除多餘油脂及髒污，還能去除淡妝，洗後肌膚保水保濕，最適合天天使用天天洗出水嫩美肌。
          </p>
        </div>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">Papawash木瓜酵素潔顏粉有分適用的對象嗎？</h5>
          <h5 class="mt-3">木瓜酵素洗顏，是不分男女，全齡皆適用。</h5>
          <p class="mt-2">
            酵素潔顏的「去除皮脂和老廢角質」，會讓人有刻板印象覺得是適用於皮脂分泌旺盛的年輕肌膚，事實不然，老廢角質的堆積與毛孔內容易有髒汙產生是每個人肌膚代謝的正常情形，透過木瓜酵素溫和又深層的潔顏過程，能讓不同年齡層的肌膚都呈現潔淨水嫩與健康光采，所以是不分男女，全齡皆適用喔。
          </p>
        </div>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">Papawash木瓜酵素潔顏粉跟別的酵素潔顏粉有什麼不同?</h5>
          <p class="mt-2">
            Papawash酵素潔顏粉選用對肌膚最溫和的木瓜酵素為洗顏粉最主要的成分，加上米糠、迷迭香、洋甘菊、水解蛋白等萃取成分，透過獨家酵素技術，完整實現獨特生酵素洗顏，讓人在第一次使用後就會有愛上Papawash的全新洗顏感受喔。
          </p>
        </div>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">Papawash木瓜酵素潔顏粉為何是粉狀?</h5>
          <h5 class="mt-3">為了讓木瓜酵素保留最好的活性，也讓生酵素洗顏能達最佳效果</h5>
          <p class="mt-2">
            「粉」劑型是添加物最少、刺激性最小、也最溫和的產品劑型。木瓜酵素粉在乾燥時，就像是睡眠狀態，能完整保留酵素的活性，當粉與水混合的瞬間，即可喚醒酵素新鮮活性，深入毛孔有效清潔，並去除老廢角質又不傷及肌膚不帶走肌膚原有水分，達到獨特生酵素洗顏。因此Papawash特別使用粉劑的形式。每一次使用都是最新鮮，可天天使用。綿密泡沫質地、舒適的洗顏觸感、好沖洗好潔淨好保濕。如此潔淨又溫和的完美平衡，讓肌膚重新回到最健康的狀態。
          </p>
        </div>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">購買Papawash需要另付運費嗎?</h5>
          <p class="mt-2">
            <span class="fw-bold">Papawash官網是全館免運費</span>(離島或偏遠地區選擇貨到付款,消費金額需滿2000元)，在您付款完成後約<span class="fw-bold">3~5</span>個工作天內送達(不含週六，日及人事行政局公告之假日、另若遇特殊節日物流量過大或特殊情況則另行通知)。
            若有任何疑問請洽詢客服專線<span class="fw-bold">0809-080-038</span>(週一至週五<span class="fw-bold">9:00-12:00，13:30-18:00</span>(例假日/國定假日休息))或於官網<router-link to="/contactus" class="text-primary fw-bold text-decoration-underline">聯絡客服</router-link>聯絡我們。
          </p>
        </div>
        <h4 class="text-primary">關於使用法</h4>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">為何Papawash木瓜酵素潔顏粉用起來覺得泡泡不多?</h5>
          <p class="mt-2">
            Papawash是利用獨家酵素技術來分解肌膚表面髒污同時溫和地深層潔淨、去除老廢角質，與利用厚重泡泡包裹去除髒污的洗顏料，本質上並不相同。因此，不需要產生大量的泡泡，就能輕鬆洗淨，省時又不必擔心殘留，在忙碌的早上或任何時候也可以很容易地使用。
          </p>
        </div>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">Papawash木瓜酵素潔顏粉可以不卸妝直接用嗎?</h5>
          <p class="mt-2">
            如果是淡妝妝容的話，請使用木瓜酵素潔顏粉清潔2次洗淨。但若是使用不易脫落的彩妝或防水處方的粉底、防曬產品時，建議先用Papawash卸妝油徹底卸妝再搭配木瓜酵素潔顏粉洗淨。
          </p>
        </div>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">Papawash木瓜酵素潔顏粉一次的使用量約多少?一瓶（60g）可以使用多少時間呢？</h5>
          <p class="mt-2">
            只要倒出約5~10元硬幣大小的洗顏粉(依膚質酌量增減)，再加一點水搓揉起泡，就可以開始體驗木瓜酵素洗顏的魅力了。每天2次(早晚使用)，大約可以使用至少2~3個月。
          </p>
        </div>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">透白款有美白成分，可以白天用？會不會反黑？</h5>
          <p class="mt-2">
            Papawash木瓜酵素潔顏粉透白型，採用的是很安定的美白成分_桑白皮，桑白皮是台灣衛福部、日本的厚生勞動省，核可的有效安全美白成分，所以白天也可以用、沒有反黑的問題喔。
          </p>
        </div>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">手部髒污時，不易起泡?</h5>
          <p class="mt-2">
            Papawash木瓜酵素洗顏粉在手部髒污時，是不易起泡的喔。<br>
            所以洗臉前請先將手部洗淨，再加入溫水揉出泡沫。<br>
            (人體皮膚程度的溫水（35~37℃）是酵素在最佳狀態下發揮作用最適宜的溫度。)
          </p>
        </div>
        <div class="mt-3 mb-5">
          <h5 class="border-bottom border-dark pb-1">Papawash木瓜酵素潔顏粉可以搭配其他品牌保養品使用嗎？</h5>
          <h5 class="mt-3">當然可以喔!</h5>
          <p class="mt-2">
            Papawash木瓜酵素潔顏洗去老廢角質、髒污及加倍護理角質是肌膚保養的完美第一步。成分配方設計都很安全、穩定性高，因此跟其他品牌搭配使用是沒有問題的。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
    h4,h5{
        font-weight: 600;
    }
</style>
